import React, { useState, useEffect } from 'react';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import { Container, CircularProgress, createTheme, ThemeProvider, AppBar, Toolbar, Button, Typography, Link, IconButton, Menu, MenuItem } from '@mui/material';
import HomePage from './pages/HomePage';
import TombolonePage from './pages/TombolonePage';
import Cartelle from './pages/CartellaPage';
import MenuIcon from '@mui/icons-material/Menu';

const theme = createTheme({
  typography: {
    "fontFamily": `"Rethink Sans", sans-serif`,
  },
  palette: {
    mode: 'dark',
    primary: {
      main: '#772E25', // Colore principale
    },
    secondary: {
      main: '#C44536', // Colore secondario
    },
    // Puoi definire ulteriori colori qui
  },
});

function LoadingScreen() {
  return (
    <div style={{ display: 'flex', justifyContent: 'between', alignItems: 'center', flexDirection: 'column', width: '100%', height: '100vh', backgroundColor: theme.palette.background.default, color: 'white' }} >
      <div></div>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: '-webkit-fill-available' }}>
        <div style={{textAlign: 'center'}}>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1, mb: 3 }}>Sto caricando la tombola!</Typography>
          <CircularProgress />
        </div> 
      </div>
      <Typography variant="body2" component="div" sx={{ flexGrow: 1, mb: 2 }}>Una webapp di Andrea Ottomanelli</Typography>
    </div>
  );
}
function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  const dataCorrente = new Date();
  const annoAttuale = dataCorrente.getFullYear();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <style>{`
          body {
            margin: 0;
          }
        `}</style>
        {isLoading ? (
          <LoadingScreen />
        ) : (
          <>
        <div style={{ backgroundColor: theme.palette.background.default }}>
        <AppBar position="sticky">
          <Container style={{display: 'flex', alignItems: 'center', height: '60px'}}>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            TombolOtto
          </Typography>
            <IconButton color="inherit" onClick={handleMenuOpen}>
              <MenuIcon />
            </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            getContentAnchorEl={null}
          >
            <MenuItem onClick={handleMenuClose} component={Link} href="/#/">Home</MenuItem>
            <MenuItem onClick={handleMenuClose} component={Link} href="/#/tombolone">Tombolone</MenuItem>
            <MenuItem onClick={handleMenuClose} component={Link} href="/#/cartelle/">Cartelle</MenuItem>
          </Menu>
          </Container>
        </AppBar>
        <Container>
            <Routes>
              <Route path="/" exact element={<><HomePage /></>} />
              <Route path="/tombolone" exact element={<><TombolonePage /></>} />
              <Route path="/cartelle" exact element={<><Cartelle /></>} />
            </Routes>
            <Typography variant="body2" component="div" sx={{ flexGrow: 1, mt: 5 }} style={{color: 'white'}}>©{annoAttuale} <Link href="https://ottomanelliandrea.it" underline="none" style={{color: 'white'}}>Andrea Ottomanelli</Link> - Tutti i diritti sono riservati</Typography>
        </Container>
        </div>
        </>
        )}
      </Router>
    </ThemeProvider>
  );
}

export default App;
