import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

function TombolonePage() {
  const [numeriEstratti, setNumeriEstratti] = useState([]);
  const [numeroEstratto, setNumeroEstratto] = useState(0);

  const estraiNumero = () => {
    // Logica per estrarre un numero dalla tombola
    const numeroCasuale = Math.floor(Math.random() * 90) + 1;
    setNumeriEstratti((prevNumeri) => [...prevNumeri, numeroCasuale]);
  };

  const tuttiNumeri = Array.from({ length: 90 }, (_, index) => index + 1);

  return (
    <div>
      <Grid container spacing={1} style={{ marginTop: '10px' }}>
        <Grid key={1} item xs={6}>
          <Button variant="contained" color="primary" onClick={estraiNumero}>
            Estrai Numero
          </Button>
        </Grid>
        <Grid key={2} item xs={6}>
          <Typography variant="h5" style={{ textAlign: 'right', color: 'white' }}>Ultimo numero estratto {numeriEstratti[numeriEstratti.length - 1]}</Typography>
        </Grid>
      </Grid>
      <Grid container spacing={1} style={{ marginTop: '10px', marginBotton: '10px' }}>
        {tuttiNumeri.map((numero) => (
          <Grid key={numero} item xs={1.33}>
            <Typography
              variant="body1"
              style={{
                border: '1px solid #ccc',
                padding: '5px',
                textAlign: 'center',
                background: numeriEstratti.includes(numero) ? '#772E25' : 'white',
                color: numeriEstratti.includes(numero) ? 'white' : 'black',
              }}
            >
              {numero}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

export default TombolonePage;