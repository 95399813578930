import React, { useState } from "react";
import { Card, CardContent, Table, TableBody, Paper, TableRow, TableCell, FormControl, Button, InputLabel, MenuItem, Select, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

class RandTools {
  // Inizializza l'array interno sequenzialmente con interi
  distRandInit (end, start = 0) {
      this.dist_rand = [];
      for (var i = start; i < end; i++) {
          this.dist_rand.push(i);
      }
  }

  // Ottiene casualmente un elemento dall'array e lo rimuove
  distRandNext () {
      return this.dist_rand.splice(Math.floor(Math.random() * this.dist_rand.length), 1)[0];
  }
}

// Componente che mostra una cartella con 15 numeri
function Cartella({ numeri }) {

  // State to manage the background color of each cell
  const [cellBackground, setCellBackground] = useState(numeri.map(row => row.map(() => false)));

  // Function to handle cell click and toggle background color
  const handleCellClick = (rowIndex, cellIndex) => {
    setCellBackground(prevCellBackground => {
      const newCellBackground = [...prevCellBackground];
      newCellBackground[rowIndex] = [...newCellBackground[rowIndex]];
  
      // Check if the cell value is not -1 before toggling background color
      if (numeri[rowIndex][cellIndex] !== -1) {
        newCellBackground[rowIndex][cellIndex] = !newCellBackground[rowIndex][cellIndex];
      }
  
      return newCellBackground;
    });
  };  

  return (
    <Card variant="outlined">
      <CardContent>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableBody>
            {numeri.map((row, i) => (
              <TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                {row.map((num, j) => (
                  <TableCell key={j} component="th" scope="row" align="center" style={{ backgroundColor: cellBackground[i][j] ? '#772E25' : 'transparent', color: 'white' }} onClick={() => handleCellClick(i, j)}>
                    {num > 0 ? num : ''}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
}

// Funzione che genera una cartella con 15 numeri casuali
function generaCartella() {
  const tools = new RandTools(); 
  const extract_pool = [];
  var card = [[], [], []];

  // Inizializzo un array per colonna
  for (var i = 0; i < 9; i++) {
      extract_pool[i] = new RandTools();
      extract_pool[i].distRandInit((i * 10) + 11, (i * 10) + 1);
  }

  // Estrazione per ogni riga
  for (var i = 0; i < 9; i++) {
      card[0].push(extract_pool[i].distRandNext());
      card[1].push(extract_pool[i].distRandNext());
      card[2].push(extract_pool[i].distRandNext());
  }

  // Buco la prima riga
  tools.distRandInit(9);
  for (var i = 0; i < 4; i++) card[0][tools.distRandNext()] = -1;

  // Buco la seconda riga
  tools.distRandInit(9);
  for (var i = 0; i < 4; i++) card[1][tools.distRandNext()] = -1;

  // Buco la terza riga in funzione delle righe
  // precendenti (ogni colonna deve avere almeno un numero)
  tools.distRandInit(9);
  var buchi = 0;
  while (buchi < 4) {
      const hit = tools.distRandNext();

      if (card[0][hit] != -1 || card[1][hit] != -1) {
          card[2][hit] = -1;
          buchi++;
      }
  }
  //console.log(card);
  return card;
}

// Componente che mostra il nome e la cartella di un giocatore
function Giocatore({ nome }) {
  const numeri = generaCartella();
  return (
    <div className="giocatore" style={{marginBottom:'10px'}}>
      <Typography variant="h6" component="div" style={{color: 'white'}}>{nome}</Typography>
      <Cartella numeri={numeri}/>
    </div>
  );
}

// Componente principale che gestisce lo stato del gioco
function Cartelle() {
  const [open, setOpen] = React.useState(false);
  // Creo lo stato per il numero dei giocatori
  const [numGiocatori, setNumGiocatori] = useState(0);
  // Creo lo stato per le cartelle dei giocatori
  const [cartelle, setCartelle] = useState([]);

  // Funzione che gestisce il cambio del numero dei giocatori
  const handleChange = (e) => {
    // Imposto il numero dei giocatori con il valore dell'input
    setNumGiocatori(e.target.value);
  };

  // Funzione che gestisce l'inizio del gioco
  const handleStart = (e) => {
    // Preveno il comportamento di default del form
    e.preventDefault();
    // Se il numero dei giocatori è valido
    if (numGiocatori > 0 && numGiocatori <= 50) {
      // Genero le cartelle per i giocatori e le salvo nello stato
      const nuoveCartelle = [];
      for (let i = 0; i < numGiocatori; i++) {
        nuoveCartelle.push(generaCartella());
      }
      setCartelle(nuoveCartelle);
    } else {
      setOpen(true);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="app"  style={{ marginTop: '20px' }}>
      {/* Se non ci sono cartelle, mostro il form per inserire il numero dei giocatori */}
      {cartelle.length === 0 ? (
        <form onSubmit={handleStart}>
        <FormControl fullWidth>
          <InputLabel htmlFor="numGiocatori">Seleziona il numero di cartelle:</InputLabel>
          <Select
            label="Numero di giocatori"
            id="numGiocatori"
            value={numGiocatori}
            onChange={handleChange}
          >
            {[...Array(50).keys()].map((num) => (
              <MenuItem key={num + 1} value={num + 1}>
                {num + 1}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
  
        <Button type="submit" variant="contained" color="primary" style={{marginTop:'5px'}}>
          Inizia
        </Button>
      </form>
      ) : (
        // Altrimenti mostro le cartelle e il pulsante per estrarre un numero
        <>
          <div className="cartelle">
            {cartelle.map((cartella, i) => (
              // Per ogni cartella, mostro il componente Giocatore con il numero del giocatore
              <Giocatore key={i} nome={`Cartella ${i + 1}`} numeri={cartella} />
            ))}
          </div>
        </>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"OPS!"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">Inserisci un numero di cartelle tra 1 e 50</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Cartelle;