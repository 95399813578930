import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Card, CardActionArea, CardContent, CardMedia, Grid, Typography, Link } from '@mui/material';

function HomePage() {
    return (
      <div>
        <Typography variant="h5" component="div" style={{ marginTop: '10px', color: 'white' }}>Benvenuto nella Tombola di Andrea Ottomanelli!</Typography>
        <Grid container spacing={1} style={{ marginTop: '10px' }}>
          <Grid key={1} item xs={6}>
            <Card>
              <CardActionArea>
                <Link href="/#/tombolone" underline="none">
                  <CardContent>
                    <Typography variant="h5" component="div" style={{'color':'white'}}>
                      Tombolone
                    </Typography>
                  </CardContent>
                  </Link>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid key={2} item xs={6}>
          <Card>
              <CardActionArea>
                <Link href="/#/cartelle" underline="none">
                <CardContent>
                  <Typography variant="h5" component="div" style={{'color':'white'}}>
                    Cartelle
                  </Typography>
                </CardContent>
                </Link>
              </CardActionArea>
            </Card>
          </Grid>
        </Grid>
      </div>
    );
  }

  export default HomePage;